<template>
  <!-- 内容 -->
  <div class="Box">
    <!-- 图片展示 -->
    <div class="imgList bbox flex Pic_group">
      <div class="Pic_item">
        <van-swipe :autoplay="3000" @change="onChange">
          <van-swipe-item
            style="height: 375px;"
            v-for="(image, index) in bannerUrl"
            :key="index"
          >
            <img v-lazy="image"  style="width:100%"/>

          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- <div class="share" @click="shareFinend">
        <img src="@/assets/images/share.png" alt="" />
      </div> -->
      <div class="num f12 textwhite">
        <span id="item2">{{ num }}</span
        >/{{ bannerUrl.length }}
      </div>
      <!-- <div class="back">
        <svg
          style="width: 25px; height: 25px"
          t="1617188644770"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2915"
          width="200"
          height="200"
        >
          <path
            d="M384 512L731.733333 202.666667c17.066667-14.933333 19.2-42.666667 4.266667-59.733334-14.933333-17.066667-42.666667-19.2-59.733333-4.266666l-384 341.333333c-10.666667 8.533333-14.933333 19.2-14.933334 32s4.266667 23.466667 14.933334 32l384 341.333333c8.533333 6.4 19.2 10.666667 27.733333 10.666667 12.8 0 23.466667-4.266667 32-14.933333 14.933333-17.066667 14.933333-44.8-4.266667-59.733334L384 512z"
            p-id="2916"
            fill="#ffffff"
          ></path>
        </svg>
      </div> -->
    </div>
    <!-- 商家信息 -->
    <div class="shopInfo bg-white p15 bbox">
      <div class="flex align-center justify-between padding margin10-b">
        <div class="f17 text111 fw600">{{ company.projectName }}</div>
        <div class="f12 text666" @click="handleFollow">
          <div v-if="followShow" class="follow">+ 关注</div>
          <div v-else class="follow_">已关注</div>
        </div>
      </div>
      <div class="iblock bblue f13">
        <span class="fblue" v-for="(item, index) in labels" :key="index">{{
          item
        }}</span>
      </div>
      <div class="mt10 flex align-center justify-between">
        <div>
          <span class="f-red f13">{{ company.followNum }}人</span
          ><span class="f-gray f13">对这个项目有合作意向</span>
        </div>
        <!-- <div class="m-btn">募资金</div> -->
      </div>
    </div>
    <!-- 介绍 -->
    <div class="evaluate bg-white mt6 bbox flex padding15">
      <div class="flex1 flex flexDcolumn align-center justify-between">
        <div class="f15 fw600 lh20">{{ company.projectTypeName }}</div>
        <div class="f12 f-gray margin10-t">行业领域</div>
      </div>
      <div class="flex1 flex flexDcolumn align-center justify-between">
        <div class="f15 fw600 lh20">{{ company.cityName }}</div>
        <div class="f12 f-gray margin10-t">总部城市</div>
      </div>
      <div class="flex1 flex flexDcolumn align-center justify-between">
        <div class="f15 fw600 lh20">{{ company.amount }}万</div>
        <div class="f12 f-gray margin10-t">投资金额</div>
      </div>
      <div class="flex1 flex flexDcolumn align-center justify-between">
        <div class="f15 fw600 lh20">{{ company.establishTime }}</div>
        <div class="f12 f-gray margin10-t">项目成立年份</div>
      </div>
    </div>
    <!-- 认证 -->
    <div class="plr20 ptb15 bg-white mt6 bbox text-left">
      <div class="padding5-t padding5-b">
        <div class="f15 fw600 margin15-b">认证机构</div>
        <div class="f38B7FD f13 flex align-center">
          <img class="margin5-r" src="../assets/images/secuity.png" alt="" />
          <div>{{ company.companyName }}</div>
        </div>
      </div>
    </div>
    <!-- 投资理由 -->
    <div class="plr20 ptb15 bg-white mt6 bbox">
      <div class="f15 fw600">投资理由</div>
      <div class="margin15-t f-333 to-info f14">
        {{ company.investReason }}
        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
        suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
        lacus vel facilisis. Risus commodo viverra maecenas accumsan lacus vel
        facilisis. -->
      </div>
    </div>
    <!-- 项目优势 -->
    <div class="plr20 ptb15 bg-white mt6 bbox">
      <div class="f15 fw600 margin15-b">项目优势</div>
      <div v-for="(item, index) in advantage" :key="index">
        <img class="w100 margin15-b" :src="item" alt="" />
      </div>
    </div>

    <!--投资人-->
    <div class="padding15-l ptb15 bg-white mt6 bbox" v-show="company.isOpen=='1' &&projectRecordsList.length>0">
      <div class="flex justify-between">
        <div class="f15 fw600">投资人</div>
        <!-- <div class="f12 f-gray">
          查看全部<svg
            style="width: 20px; height: 10px"
            t="1617188759446"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2918"
            width="200"
            height="200"
          >
            <path
              d="M320 885.333333c-8.533333 0-17.066667-4.266667-23.466667-10.666666-12.8-12.8-10.666667-34.133333 2.133334-44.8L654.933333 512 298.666667 194.133333c-12.8-10.666667-14.933333-32-2.133334-44.8 10.666667-12.8 32-14.933333 44.8-2.133333l384 341.333333c6.4 6.4 10.666667 14.933333 10.666667 23.466667 0 8.533333-4.266667 17.066667-10.666667 23.466667l-384 341.333333c-6.4 6.4-12.8 8.533333-21.333333 8.533333z"
              p-id="2919"
              fill="#bfbfbf"
            ></path>
          </svg>
        </div> -->
      </div>
      <div 
        class="margin15-t margin15-r"
        v-for="(item, index) in projectRecordsList"
        :key="index"
        
      >
        <div class="flex justify-between align-center f12 margin15-b">
          <div class="flex justify-between align-center">
            <!-- <img
              class="user-avatar"
              src="@/assets/images/prodetail02.png"
              alt=""
            /> -->
            <div class="margin5-l">{{ item.name }}</div>
          </div>
          <div>{{ subPhone(item.phone) }}</div>
          <div>投资{{ item.amount }}万元</div>
          <div>{{ formatTimes(item.createDt) }}</div>
        </div>
      </div>

      <!-- 底部弹出层 -->
      <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
      <van-popup
        v-model="show"
        position="bottom"
        :style="{
          height: '35%',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }"
      >
        <div class="padding10">
          <div class="flex justify-between">
            <div class="f15 f333 fw600">私信给朋友</div>
            <van-icon
              name="cross"
              @click="
                () => {
                  this.show = false;
                }
              "
            />
          </div>
          <div class="margin15-t flex fins">
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 text-center">王玉玉</div>
            </div>
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 text-center">王玉玉</div>
            </div>
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 v">王玉玉</div>
            </div>
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 v">王玉玉</div>
            </div>
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 v">王玉玉</div>
            </div>
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 v">王玉玉</div>
            </div>
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 v">王玉玉</div>
            </div>
            <div class="margin20-r">
              <van-image
                round
                width="46px"
                height="46px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <div class="f12 f333 v">王玉玉</div>
            </div>
          </div>
          <div class="flex justify-between toriun margin10-t">
            <div class="margin20-r">
              <!-- <van-image
                round
                width="46px"
                height="46px"
                src="~@/assets/images/wx.png"
              /> -->
              <img src="../assets/images/wx.png" alt="" />
              <div class="f12 f333 text-center margin5-t">微信</div>
            </div>
            <div class="margin20-r">
              <!-- <van-image
                round
                width="46px"
                height="46px"
                src="~@/assets/images/firends.png"
              /> -->
              <img src="../assets/images/firends.png" alt="" />
              <div class="f12 f333 text-center margin5-t">朋友圈</div>
            </div>
            <div class="margin20-r" @click="banPoster">
              <!-- <van-image
                round
                width="46px"
                height="46px"
                src="~@/assets/images/poster.png"
              /> -->
              <img src="../assets/images/poster.png" alt="" />
              <div class="f12 f333 text-center margin5-t">生成海报</div>
            </div>
          </div>
        </div>
      </van-popup>

      <van-popup v-model="show1" style="background: transparent">
        <div style="width: 336px">
          <img
            style="width:336px;411px"
            src="../assets/images/logo.png"
            alt=""
          />
          <div class="flex padding12 bg-white">
            <div class="margin8-r">
              <div class="f17 text111 fw600">阿尔兹海默症脑细胞PCR测序</div>
              <div class="iblock bblue f13">
                <span class="fblue">中科院上海生命科学研究院直属项目</span>
              </div>
              <div class="flex align-center margin15-t">
                <van-image
                  round
                  width="22px"
                  height="22px"
                  src="https://img01.yzcdn.cn/vant/cat.jpeg"
                />
                <span class="f16 margin5-l">东北水饺大饼推荐</span>
              </div>
            </div>
            <div class="flex flexDcolumn">
              <van-image
                width="80px"
                height="80px"
                src="https://img01.yzcdn.cn/vant/cat.jpeg"
              />
              <span class="f11">长按识别查看</span>
            </div>
          </div>
        </div>
        <div class="mt20 flex justify-center">
          <van-button type="primary" size="large" class="down"
            >保存海报</van-button
          >
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { formatTime } from "../utils/utils";
export default {
  data() {
    return {
      followShow: 0,
      show: false,
      show1: false,
      num: 1,
      bannerUrl: [],
      company: {},
      advantage: [],
      labels: [],
      projectRecordsList: [],

      projectCode: "",
      memberCode: "",
    };
  },
  mounted() {
    this.projectCode =this.getUrlKey("projectCode");
    this.memberCode = this.getUrlKey("memberCode");
    this.queryOne(this.projectCode);
    this.queryIsLikeOrCollect(this.memberCode, this.projectCode);
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
    },
  },
  methods: {
    getUrlKey(name){//获取url 参数
      return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
    },

    queryOne(projectCode) {
      this.$axios({
        url: "/companyProject/queryOne",
        data: { projectCode: projectCode },
        method: "post",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          var data = res.data;
          this.company = data;
          this.bannerUrl = data.bannerUrl.split(",");
          this.advantage = data.advantage.split(",");
          this.labels = data.labels.split(",");
          this.projectRecordsList = data.projectRecordsList;
        }
      });
    },

    //获取粉丝和关注数量
    queryFollowCount() {
      this.$axios({
        url: "/memberFollow/queryFollowCount",
        data: { memberCode: "0" },
        method: "post",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          var data = res.data;
        }
      });
    },

    //获取是否关注
    queryIsLikeOrCollect(memberCode, objectCode) {
      this.$axios({
        url: "/memberCollectLike/queryIsLikeOrCollect",
        data: {
          memberCode: memberCode,
          objectCode: objectCode,
          type: 3,
        },
        method: "post",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          var data = res.data;
          this.followShow = data;
        }
      });
    },

    //添加或取消关注
    addOrCancelFollow(memberCode, objectCode) {
      this.$axios({
        url: "/memberCollectLike/addOrCancelCollect",
        data: {
          memberCode: memberCode,
          objectCode: objectCode,
          type: 3,
        },
        method: "post",
      }).then((res) => {
        // console.log(res)
        if (res.code == 200) {
          var data = res.data;
        }
      });
    },

    subPhone(str){
      return str.substring(0,3)+'****'+str.substring(7)
    },

    handleFollow() {
      this.followShow = !this.followShow;
      this.addOrCancelFollow(this.memberCode, this.projectCode);
    },

    formatTimes(date) {
      return formatTime(date);
    },

    gotoInvesrors() {
      this.$router.push({ path: "/Investors" });
    },
    shareFinend() {
      this.show = true;
    },
    onChange(e) {
      //   console.log(e);
      this.num = e + 1;
    },
    banPoster() {
      this.show1 = true;
      this.show = false;
    },
  },
};
</script>

<style scoped>
@import "../assets/css/style.css";
@import "../assets/css/common.css";
.shopInfo {
  text-align: left;
}
.iblock {
  display: inline-block;
  padding: 3px 5px;
  margin-top: 10px;
}
.follow {
  border: 1px solid #41b7fd;
  padding: 1px 8px 2px 8px;
  border-radius: 10px;
  color: #41b7fd;
  display: flex;
  align-items: center;
}
.follow_ {
  border: 1px solid #41b7fd;
  padding: 1px 8px 2px 8px;
  border-radius: 10px;
  background: #41b7fd;
  color: #fff;
  display: flex;
  align-items: center;
}
.to-info {
  line-height: 23.5px;
  word-break: break-all;
}
.m-btn {
  padding: 5px 15px;
  border-radius: 25px;
  background: linear-gradient(0deg, #4baffd 0%, #01d1fd 99%);
  color: #fff;
  font-size: 13px;
}
.fins {
  flex-wrap: nowrap;
  overflow-x: scroll;
  /* overflow-x: hidden; */
  padding-bottom: 10px;
}
.fins::-webkit-scrollbar {
  display: none;
}
.toriun {
  padding: 10px 50px;
}
.down {
  background: linear-gradient(0deg, #4baffd 0%, #01d1fd 99%);
  width: 300px;
  border-radius: 30px;
}
.Pic_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Pic_item >>> .van-swipe__indicators {
  display: none;
}
</style>