const formatTime = date => {
    var time = new Date(date)
    const year = time.getFullYear()
    const month = time.getMonth() + 1
    const day = time.getDate()
    // const hour = time.getHours()
    // const minute = time.getMinutes()
    // const second = time.getSeconds()

    return `${year+'.'+month+'.'+day}`
}


export {
    formatTime
}